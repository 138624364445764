import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AvatarContainer, BackgroundImgContainer, UsuallyButton} from '../../components';
import {assets} from '../../assets';
import {AvatarsBlockContainer, Container, Description, Devider, FightButtonContainer, styles, Title} from './styles';
import {colors} from '../../constants';
import {EStatusFightAvatar} from '../../components/layout/AvatarContainer/types';
import {
  useAppDispatch,
  useAppSelector,
  useFormattedFightData,
  useGetUserFightsDataQuery,
  useToggleUserFightStatusMutation,
} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {useNavigate} from 'react-router-dom';
import {EFightSearchingStatus} from './types';
import {setLastFightAction} from '../../reducers/fight';
import { EStatusType } from '../ComingSoon/types';

type TProps = {};

const SearchingOpponents: React.FC<TProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [pollingInterval, setPollingInterval] = useState(0);

  const {data: user} = useAppSelector(selectUser);
  const {
    data: fightData,
    refetch,
    isLoading: isLoadingFightData,
  } = useGetUserFightsDataQuery({user_id: user?.id ?? ''}, {skip: !user?.id, pollingInterval: pollingInterval});

  const [toggleUserFightStatus, {isLoading, error, data: fightStatusData}] = useToggleUserFightStatusMutation();

  const formattedFightData = useFormattedFightData(fightData?.fight, user?.id ?? null);

  const fightStatus = useMemo(() => {
    if (
      (fightStatusData?.is_search_fight && fightStatusData.fight) ||
      (fightData?.is_search_fight && fightData.fight)
    ) {
      return EFightSearchingStatus.TRUE;
    }
    if ((!fightStatusData && !fightData?.is_search_fight) || (fightStatusData && !fightStatusData?.is_search_fight)) {
      return EFightSearchingStatus.FALSE;
    }
    return EFightSearchingStatus.SEARCHIND;
  }, [fightData, fightStatusData]);

  const onPressToggleUserFightStatus = useCallback(
    (fightStatus: EFightSearchingStatus, userId: string) => async () => {
      if (fightStatus === EFightSearchingStatus.TRUE) {
        navigate('/game');
      } else if (fightStatus === EFightSearchingStatus.FALSE) {
        if (user?.id) {
          await toggleUserFightStatus({user_id: userId});
        }
      } else {
        await toggleUserFightStatus({user_id: userId});
      }
    },
    [],
  );

useEffect(() => {
  if (fightStatus === EFightSearchingStatus.SEARCHIND) {
    refetch();
    setPollingInterval(6000);
  } else {
    if (fightStatus === EFightSearchingStatus.TRUE) {
      if (formattedFightData) {
        dispatch(setLastFightAction(formattedFightData?.id));
      }
            if (fightStatusData) {
        dispatch(setLastFightAction(fightStatusData.id));
      }
    }
    setPollingInterval(0);
  }
}, [fightStatus, formattedFightData]);



  const getFightOpponentName = () => {
    if (fightStatus === EFightSearchingStatus.TRUE) {
      if (fightStatusData && fightStatusData.fight) {
        if (fightStatusData.fight.fight_host.id !== user?.id) {
          return `${fightStatusData.fight.fight_host.first_name} ${fightStatusData.fight.fight_host.last_name}`;
        } else {
          return `${fightStatusData.fight.opponent.first_name} ${fightStatusData.fight.opponent.last_name}`;
        }
      } else if (fightData && fightData.fight) {
        if (fightData.fight.fight_host.id !== user?.id) {
          return `${fightData.fight.fight_host.first_name} ${fightData.fight.fight_host.last_name}`;
        } else {
          return `${fightData.fight.opponent.first_name} ${fightData.fight.opponent.last_name}`;
        }
      }
    } else if (fightData && fightData.fight) {
      return `${fightData.fight.opponent.first_name} ${fightData.fight.opponent.last_name}`;
    }
    return '';
  };

  return (
    <BackgroundImgContainer>
      <Container>
   <Title>{fightStatus !== EFightSearchingStatus.SEARCHIND ? 'Бийтеся з друзями' : 'Ми шукаємо...'}</Title>
        <Description>
        {fightStatus !== EFightSearchingStatus.SEARCHIND ? 'Запрошуй друзів і майни разом! Гравці з найбільшою мережею друзів зазвичай отримують найбільші бонуси' : 'Ми повідомимо вас, коли знайдемо суперника'}
        </Description>
        <AvatarsBlockContainer>
        <AvatarContainer
            $size={`calc((100vw - 92px - 27px) / 2)`}
            url={assets.userAvatar}
            status={EStatusFightAvatar.READY}
            title="(Ви)"
            subTitle={user ? `${user?.first_name} ${user?.last_name}` : ''}
          />
          <Devider>VS</Devider>
          <AvatarContainer
            $size={`calc((100vw - 92px - 27px) / 2)`}
            isOpponent
            status={
              fightStatus === EFightSearchingStatus.SEARCHIND
                ? EStatusFightAvatar.SEARCHING
                : fightStatus === EFightSearchingStatus.FALSE
                ? EStatusFightAvatar.NOT_AVAILABLE
                : EStatusFightAvatar.READY
            }
            url={fightStatus === EFightSearchingStatus.TRUE ? assets.opponentAvatar : assets.emptyOpponentAvatar}
            title="(Ваш опонент)"
            subTitle={getFightOpponentName()}
          />
        </AvatarsBlockContainer>
        <FightButtonContainer>
          {/* {fightStatus !== EFightSearchingStatus.FALSE ? <DescriptionBlock status={fightStatus} /> : null} */}
          <UsuallyButton
            title={
              fightStatus === EFightSearchingStatus.TRUE
                ? 'Бій!'
                : fightStatus === EFightSearchingStatus.SEARCHIND
                ? 'Припинити пошук'
                : 'Почати пошук'
            }
            disable={isLoading}
            leftIcon={fightStatus === EFightSearchingStatus.TRUE ? EStatusType.FIGHT : ''}
            containerStyle={styles.btnStyle}
            $color={colors.white}
            onClick={onPressToggleUserFightStatus(fightStatus, user?.id ?? '')}
            background={
              fightStatus === EFightSearchingStatus.TRUE
                ? `linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`
                : fightStatus === EFightSearchingStatus.SEARCHIND
                ? `rgba(255, 255, 255, 0.2)`
                : `linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`
            }
          />
        </FightButtonContainer>
      </Container>
    </BackgroundImgContainer>
  );
};

export default SearchingOpponents;
